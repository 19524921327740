<template>
    <div class="square cambio"><router-link :to="'/es/comunicacion/noticias/'+data.slug" class="enlace-cover"></router-link>

        <div class="contenedor-imagen"
            :style="`background:#d5d5d5 url(${data.header_img.url}) center/cover no-repeat;`">
        </div>

        <div class="contenedor-datos">
            <h5><span>{{data.title}}</span></h5>
            <p>{{data.header}}</p>
            <router-link :to="'/es/comunicacion/noticias/'+data.slug">Leer noticia</router-link>
        </div>
    </div>
</template>

<script>
    export default{
        name : 'noticia',
        props : ['data']
    }
</script>