<template>

  <div v-if="!!data">

    <div class="info-container mb0">

      <h3 data-to="eventos" v-if="data.length != 0 || datapasados.length != 0 || dataeco.length != 0">
        <strong> Te invitamos a:</strong>
      </h3>

      <template v-if="data.length != 0">
        <div class="contenedor-squares js-prev-eventos ais row-start fww" v-if="data.length != 0">
          <div class="square cambio" :key="key" v-for="(el,key) in data">
            <router-link :to="'/eventos/'+el.slug" class="enlace-cover"></router-link>
            <div class="contenedor-imagen" :style="`background:#444 url(${el.image.url}) center/cover no-repeat;`">
            </div>
            <div class="contenedor-datos">
              <h5><span>{{el.title}}</span><span>{{el.date | date}}</span></h5>
              <br>
              <router-link :to="'/eventos/'+el.slug">Sobre el evento</router-link>
            </div>
          </div>
        </div>
        <p class="text-center" v-else>No hay eventos próximos</p>
      </template>
      <router-link id="aVerTodosEventos" to="/eventos/" class="boton-azul mb-50">Ver todos</router-link>
    </div>




    <section class="eco-section" v-if="dataeco.length != 0">
      <div class="info-container">
        <h4 class="js-proximos-eventos">ECO UFV</h4>
        <p class="text-center">Otras actividades que tendrán lugar en la UFV a las que puedes apuntarte</p>
        <div class="contenedor-squares js-prev-eventos ais row-start fww">
          <div class="square cambio" :key="key" v-for="(el,key) in dataeco">
            <router-link :to="'/eventos/'+el.slug" class="enlace-cover"></router-link>
            <div class="contenedor-imagen" :style="`background:#444 url(${el.image.url}) center/cover no-repeat;`">
            </div>
            <div class="contenedor-datos">
              <h5><span>{{el.title}}</span><span>{{el.date | date}}</span></h5>
              <router-link :to="'/eventos/'+el.slug">Sobre el evento</router-link>
            </div>
          </div>
        </div>
        <router-link id="aVerTodosEventos" to="/eventos/" class="boton-azul">Ver todos</router-link>
      </div>

    </section>

    <section class="pasados-section" v-if="datapasados.length != 0">
      <div class="info-container">
        <h4 class="js-proximos-eventos">Vuelve a ver:</h4>
        <template v-if="datapasados.length != 0">
          <div class="contenedor-squares  js-prev-eventos ais row-start fww">
            <div class="square cambio" :key="key" v-for="(el,key) in datapasados">
              <router-link :to="'/eventos/'+el.slug" class="enlace-cover"></router-link>
              <div class="contenedor-imagen" :style="`background:#444 url(${el.image.url}) center/cover no-repeat;`">
              </div>
              <div class="contenedor-datos">
                <h5><span>{{el.title}}</span><span>{{el.date | date}}</span></h5>
                <router-link :to="'/eventos/'+el.slug" >Sobre el evento</router-link>
              </div>
            </div>
          </div>
          <router-link id="aVerTodosEventos" to="/eventos/" class="boton-azul alone mb50">Ver todos</router-link>
        </template>
        <p class="text-center" v-else>No hay eventos próximos</p>
      </div>
    </section>
  </div>
</template>


<script>

  import { mapActions } from 'vuex';

  export default {
    name: 'eventos',
    mounted() {
      this.setData();
    },
    data: () => ({
      data: '',
      datapasados: '',
      dataeco: ''
    }),
    methods: {
      ...mapActions(['requestEventos', 'requestEventosPasados', 'requestEventoEco']),
      setData() {
        this.requestEventos().then(r => {
          this.data = r.data;
        });

        this.requestEventosPasados().then(r => {
          this.datapasados = r.data;
        })

        this.requestEventoEco().then(r => {
          this.dataeco = r.data;
        })
      }
    }
  }
</script>


<style lang="scss" scoped>
  .square h5 {
    margin-top: 0;
  }

  .square a:not(.enlace-cover){
    margin-top: 0;
    bottom: 20px;
  }

  .square.cambio{
    align-items: stretch;
  }

  .eco-section .square a:not(.enlace-cover){
    margin-top: 20px;
    width: 100%;
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    display: inline-block;
    padding-left: 0;
  }

</style>