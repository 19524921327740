<template>
  <div class="wrapper">

    <section data-to="equipo-alumni" class="encabezado equipo-alumni row-start">
      <h2>UFV Familias</h2>
    </section>


    <section class="info-container">

      <h3 data-to="quienes-somos">Te ofrecemos</h3>

      <div class="imagen-texto row-start equipo">

        <div class="imagen">
        <video controls   poster="/img/portada-video.png"  src="/img/video/video-home.mp4">        
        </video>





        </div>

        <div class="texto">

          <p>
            <strong>UFV Familias te ofrece un lugar de encuentro para familias y amigos de la Universidad Francisco de
              Vitoria</strong>, donde, impulsados por la búsqueda de la Verdad, el Bien y la Belleza, <strong>queremos
              acercarte la riqueza de conocimiento</strong> de la Universidad Francisco de Vitoria.<br><br>Inspirados en la misión y modelo formativo de esta universidad, <strong>UFV Familias te ofrece un programa
              variado de eventos y actividades formativas</strong>, que esperamos sirvan de impulso para abordar los
            desafíos del presente.
          </p>

        </div>

      </div>





      <h3 data-to="bienvenida">UFV Familias te da la bienvenida!</h3>

      <p class="text-center">Para conocer de todos los eventos que organiza UFV Familias, regístrate pinchando <a
          href="">aquí</a>.</p>

      <a href="https://www.alumni-ufv.es/es/general/encuesta-padres" target="_blank" class="boton-azul inscribir">INSCRÍBETE</a>

    </section>

    <eventos></eventos>

    <section class="info-container">

      <h3 data-to="noticias">Noticias</h3>

      <noticias></noticias>

    </section>






  </div>
</template>

<script>

  import noticias from '@/components/noticias-home'

  import eventos from '@/components/eventos-home'

  export default {
    name: 'Home',
    mounted() {
      this.checkscroll();
    },
    data: () => ({
      showresponsables: false
    }),
    methods: {
      checkscroll() {
        if (this.$route.hash) {
          setTimeout(() => {
            let offsetTop = document.querySelector(`*[data-to=${this.$route.hash.split("#")[1]}]`).offsetTop;
            scroll({
              top: offsetTop,
              behavior: "smooth"
            });
          }, 1000);
        }
      }
    },
    components: {
      'noticias': noticias,
      'eventos': eventos
    },
    watch: {
      '$route'() {
        this.checkscroll();
      }
    }
  }
</script>